<template>
	<div class="hHun wHun loginContent bgsHun">
		<div class="login_Header">
			<div class="header_Icon bgsHun hHun fl"></div>
			<div class="header_Ttile">图像识别处理系统</div>
			<div class="header_En">Image recognition processing system</div>
		</div>
		<div class="login_LoginContent">
			<div class="log_Head ac">欢迎登录系统<div class="log_Border"></div>
			</div>
			<div class="login_c wHun">
				<div class="login_input wHun">
					<i class="login-user bgsHun"></i>
					<input v-model="ruleForm.username" @keyup.enter="submitForm" type="text" placeholder="请输入登录账号" />
				</div>
				<div class="login_input wHun">
					<i class="login_password bgsHun"></i>
					<input v-model="ruleForm.password" @keyup.enter="submitForm" type="password"
						placeholder="请输入登录密码" />
				</div>
				<!-- <div class="login_rem wHun">
					<div class="fl wFive al">
						<label><el-checkbox v-model="checkPasswordState">记住密码</el-checkbox></label>
					</div>
					<div class="fr wFive ar">忘记密码？</div>
				</div> -->
				<div class="login_btn ac" :loading="loading" @click="submitForm">登录</div>
			</div>
		</div>
		<div class="tecName wHun ac">建科智能装备制造(天津)股份有限公司</div>

	</div>
</template>
<script>
	import {
		requestLogin,
	} from '@/api/login/login'
	export default {
		inject: ['reload'],
		data() {
			return {
				bgHeight: '',
				ruleForm: {
					username: '',
					password: ''
				},
				checkPasswordState: true,
				loading: false
			};
		},
		watch: {
			$route: {
				handler: function(route) {
					//console.log(route);
					this.redirect = route.query && route.query.redirect;
				},
				immediate: true
			}
		},
		mounted() {
			this.bgHeight = document.body.scrollHeight - 3 + "px";
			this.getlocal();
		},
		methods: {
			RetrievePassword() {
				this.dialogFormVisible = true;
			},
			getlocal() {
				if (window.localStorage.getItem('account') != null) {
					this.ruleForm.username = window.localStorage.getItem('account');
					this.ruleForm.password = window.localStorage.getItem('password');
				}
			},
			CheckItem(item) {
				item.state = !item.state;
			},
			submitForm(formName) {
				this.loading = true;
				this.disabled = true;
				if (formName.username === '' || formName.password === '') {
					this.$message.error('用户名或密码不能为空');
					this.loading = false;
					this.disabled = false;
				} else {
					let data = {
						account: this.ruleForm.username,
						password: this.ruleForm.password
					}
					requestLogin(data).then(response => {
						console.log(response.data);
						if (response.data && response.data.code === 'fail') {
							this.$message.error(response.data.msg);
							this.loading = false;
							this.disabled = false;
						} else if (response.data && response.data.code === '未知错误null') {
							this.$message.error(response.data.msg);
							this.loading = false;
							this.disabled = false;
						} else {
							if (this.checkPasswordState == true) {
								window.localStorage.setItem('account', this.ruleForm.username);
								window.localStorage.setItem('password', this.ruleForm.password);
							} else {
								window.localStorage.removeItem('account');
								window.localStorage.removeItem('password');
							}
							window.localStorage.setItem("token", response.data.data.token); //token
							window.localStorage.setItem("sessionId", response.data.data.sessionId); //token
							window.localStorage.setItem(
								"expires",
								response.data.data.freshdate
							); //刷新时间
							window.localStorage.setItem(
								"refresh_expires_in",
								response.data.data.validate
							); //有效时间
							// window.localStorage.setItem('token', response.data.data.token); //token
							// window.localStorage.setItem('expires', response.data.data.freshdate); //刷新时间
							// window.localStorage.setItem('refresh_expires_in', response.data.data.validate); //有效时间
							this.loading = false;
							this.disabled = true;
							this.$router.replace('/homePage');
							location.reload();
						}

					})
				}
			}
		}
	};
</script>
<style scoped>
	.loginContent {
		width: 100vw;
		height: 100vh;
		overflow: hidden;
		background-image: url(../../assets/img/login/login_Bg.png);
	}

	.login_Header {
		height: 44px;
		width: 300px;
		position: relative;
		left: 20px;
		top: 20px;
	}

	.header_Icon {
		width: 44px;
		background-image: url(../../assets/img/login/login_HeaderIcon.png);
	}

	.header_Ttile {
		position: absolute;
		left: 50px;
		height: 32px;
		line-height: 32px;
		font-family: PingFangSemibold;
		font-size: 24px;
		color: #000000;
		letter-spacing: 1px;
	}

	.header_En {
		font-family: PingFangSemibold;
		height: 12px;
		line-height: 12px;
		font-size: 10px;
		color: #919AA5;
		position: absolute;
		bottom: 0px;
		left: 50px;
	}

	.login_LoginContent {
		position: absolute;
		top: 30vh;
		right: 10%;
		width: 20%;
		/* font-size: calc(100vw * 28 / 1920) !important; */
		height: 40vh;
		background: #FFFFFF;
		border-radius: 10px 10px 10px 10px;
		border: 3px solid #FFFFFF;
	}

	.log_Head {
		font-family: PingFangSemibold;
		font-size: calc(100vw * 26 / 1920) !important;
		height: 8vh;
		/* border: 1px solid red; */
		line-height: 8vh;
		position: relative;
	}

	.log_Border {
		width: 48px;
		height: 6px;
		background: #165DFF;
		border-radius: 2px 2px 2px 2px;
		position: absolute;
		top: 7vh;
		right: 43%;
	}

	.login_c {
		margin-top: 15%;
		padding: 0px 1vw;
	}

	.login_input {
		line-height: 5vh;
		cursor: pointer;
		height: 5vh;
		background: #F2F3F5;
		border-radius: 4px;
		border: 1px solid #dcdee0;
		margin-bottom: 20px;
	}

	.login_input input {
		font-family: PingFangSemibold;
		height: 5vh;
		border: none;
		border-bottom: 1px solid #fff;
		background-color: transparent;
		outline: none;
		padding-left: 5px;
		line-height: 5vh;
		/* color: #fff; */
		font-size: calc(100vw * 16 / 1920) !important;
		border-radius: 0px;
	}

	.login_input i {
		display: inline-block;
		height: 2vh;
		width: 1vw;
		margin: 0px .5vw .7vh .5vw;
		vertical-align: middle;
	}

	.login-user {
		background-image: url(../../assets/img/login/login_User.png);
	}

	.login_password {
		background-image: url(../../assets/img/login/login_password.png);
	}

	input:-webkit-autofill,
	textarea:-webkit-autofill,
	select:-webkit-autofill {
		-webkit-text-fill-color: #333 !important;
		-webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
		background-color: transparent;
		background-image: none;
		transition: background-color 50000s ease-in-out 0s;
	}

	input {
		background-color: transparent;
	}

	.login_btn {
		font-family: PingFangSemibold;
		margin-left: 10%;
		margin-top: 15%;
		width: 80%;
		height: 4vh;
		font-size: calc(100vw * 18 / 1920) !important;
		font-weight: 500;
		color: #ffffff;
		background: #155bd4;
		border-radius: 4px;
		line-height: 4vh;
	}
	.tecName{
		width: 100%;
		font-family: PingFangSemibold;
		font-weight: 500;
		font-size: calc(100vw * 16 / 1920) !important;
		color: #333333;
		position: absolute;
		bottom: 20px;
	}
</style>