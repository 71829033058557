<template>



  <v-if :key="item_List" v-show="item_List">
    <div class="grid-content bg-purple userMain hHun" v-show="item_List">

      <!-- <div class="searchBox"> -->

      <!-- 
        {
  //             "EstimatedTime": "0秒",
  //             "TransferComplete": 1,
  //             "speedProgress": 6,
  //             "totalProgress": 6,
  //             "taskName": "1725284917999_叠合板.pdf",
  //             "AverageTime": 3718
  //         }
 -->

      <el-row class="row-bg" justify="space-between">
        <el-col :span="6">
          <div class="tabName">图像识别</div>
        </el-col>
        <el-col :span="5">
          <el-dropdown @visible-change="handleVisible2">
            <span class="el-dropdown-link">
              当前服务器运行中的任务
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-scrollbar max-height="500px">
                <el-dropdown-menu>

                  <p v-if="ProgressList.length == 0">
                    <el-dropdown-item>暂无正在运行的任务...</el-dropdown-item>
                  </p>

                  <p v-else-if="ProgressList.length > 0">
                    <el-dropdown-item v-for="(item, index) in ProgressList" :key="index" :index="index"
                      @click="selectTask(item)">
                      <p v-if="item.TransferComplete == 1"> 转换完成 | {{ item.taskName }} | 总数量：{{ item.totalProgress }}
                      </p>
                      <p v-else> 转换中 | {{ item.taskName }} | 预计用时：{{ item.EstimatedTime }} | 总数量：{{ item.totalProgress
                        }}张</p>



                    </el-dropdown-item>
                  </p>

                </el-dropdown-menu>
              </el-scrollbar>
            </template>
          </el-dropdown>
        </el-col>



      </el-row>


      <el-row :gutter="30" style="margin-bottom: 20px;">
        <el-col :span="12">

          <div style="height: 620px; overflow: hidden;  width:100% "> <canvas id="myCanvas"></canvas> </div>

        </el-col>
        <el-col :span="12">
          <el-scrollbar max-height="620px">
            <el-table :data="tableData" style="width: 100%" height="100%" :cell-class-name="getCellClass">
              <el-table-column type="index" :index="indexMethod" label="编号" width="90px"> </el-table-column>
              <el-table-column prop="Start" label="起始X,Y坐标"> </el-table-column>
              <el-table-column prop="End" label="终点X,Y坐标">
              </el-table-column>
              <el-table-column prop="Pixel" label="像素长度"> </el-table-column>
              <el-table-column prop="Actual" label="实际长度"> </el-table-column>
              <el-table-column prop="Hook" label="是否带弯钩"> </el-table-column>
            </el-table>
          </el-scrollbar>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="2">
          <el-button type="primary" @click="draw_line">画直线</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="tableData_draw_line">遍历数据</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="clearAllObject">清除</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="drawer = true">打开文件浏览</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- </div> -->
  </v-if>

  <el-dialog v-model="centerDialogVisible" title="成功识别" width="500" center>
    <span>
      目前已经成功识别图纸到数据库，请问是否现在打开？
    </span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="SuccessfullyOpenedEvent">
          打开
        </el-button>
      </div>
    </template>
  </el-dialog>

  <!-- 放大弹窗 -->
  <el-dialog v-model="isEnlargeImage" :modal-append-to-body="false" top="3%" width="80%" z-index="2001">
    <img style="width:100%;" :src="OriginalImage_url">
  </el-dialog>

  <el-drawer v-model="drawer" direction='ltr' z-index="2000">
    <template #header>
      <h4>选择图纸</h4>
    </template>
    <template #default>
      <el-tree style="max-width: 100%" :props="props" :load="LazyLoadingData" lazy @node-click="handleNodeClick" />
    </template>
    <template #footer>
      <el-text size="small">选择图纸为： {{ TargetFile }} </el-text>
      <div style="flex: auto">
        <el-button type="success" round @click="ViewOriginalImage">查看</el-button>
        <el-button type="primary" round @click="confirmClick">识别</el-button>
      </div>
    </template>
  </el-drawer>

  <div class="grid-content bg-purple userMain hHun" v-show="!item_List">
    <div class="tabName">当前可识别的文件列表为</div>


    <button type="primary" @click='drawer = true'>打开选择图纸</button>
  </div>

</template>


<script>

import Mx from 'mxdraw';
import { getDataDistinguish } from "@/api/folder/folder";
import * as api from "@/api/folder/folder";
import { ElLoading } from 'element-plus'


export default {
  components: {
    Mx,
  },


  data() {
    return {
      draw: null,
      drawer: true,
      Scale_Multiple: 1,
      item_List: true,

      props: {
        label: 'name',
        path: 'path',
        isLeaf: 'leaf'
      },

      dropdown: true,
      isEnlargeImage: false,
      OriginalImage_url: '',

      // 用于存放图纸数据
      listLayer: [],
      tableData: [],
      ProgressList: [],
      indexMethod: 1,
      TargetFile: "NULL", // 当前目标文件

      PixelLengthThreshold: [50, 8000],    // 像素长度阈值  
      ActualLengthThreshold: [50, 6000],  // 实际长度阈值
      ProScope: [50, 50, 5600, 5500], // [xmin, xmax, ymin, ymax]   // 加工范围
      centerDialogVisible: false,

    }
  },
  mounted() {

    // 动态加载 js库核心代码
    Mx.loadCoreCode().then(async () => {
      Mx.MxFun.createMxObject({
        canvasId: "myCanvas", // canvas元素的id
        cadFile: "http://localhost:8886/#",
        callback(mxDraw, dom) {
          mxDraw.setViewColor(0xffffff); // 设置背景颜色

        },

      });

      this.draw = Mx.MxFun.getCurrentDraw();



      this.draw.zoomW(this.xy2Doc(1, 1), this.xy2Doc(5500, 5500)) // 设置缩放比例
      this.Scale_Multiple = this.draw.getViewWidth() / 5500 // 获取缩放比例
      this.MxDraw_f5();

    })



  },





  methods: {

    handleVisible2(visible) {
      if (visible) {
        api.getProgress().then(res => {
          if (res.data.code == "success") {
            this.ProgressList = res.data.data
          }
        })

      }

    },


    SuccessfullyOpenedEvent() {
      this.centerDialogVisible = false
      api.getDataDistinguish(this.TargetFile).then(res => {
        // console.log(res.data.data)
        this.DataProcess(res.data.data)

        this.tableData_draw_line()
        this.draw_Rect(this.ProScope[0], this.ProScope[1], this.ProScope[2], this.ProScope[3])
        // console.log(this.tableData)
        // 画矩形

      })
      this.drawer = false
      this.item_List = true

    },

    ViewOriginalImage() {
      // api.OriginalImage(this.TargetFile).then((res) => {
      //   console.log(res.data.data)

      // })
      this.OriginalImage_url = "http://47.105.43.195:8886/OriginalImage?path=" + this.TargetFile.split("\\")[0] + "&name=" + this.TargetFile.split("\\")[1]
      this.isEnlargeImage = true

    },

    confirmClick() {

      this.loading = ElLoading.service({
        lock: false,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })


      api.executeDistinguish(this.TargetFile).then((res) => {
        if (res.data.code == "success") {

          this.centerDialogVisible = true
          this.drawer = false
          this.item_List = true
          this.loading.close()

        } else if (res.data.code == "fail") {
          this.$notify.success({
            title: "已经识别",
            message: res.data.msg,
          });
          api.getDataDistinguish(this.TargetFile).then(res => {
            // console.log(res.data.data)
            this.DataProcess(res.data.data)

            this.tableData_draw_line()
            this.draw_Rect(this.ProScope[0], this.ProScope[1], this.ProScope[2], this.ProScope[3])
            // console.log(this.tableData)
            // 画矩形

          })
          this.drawer = false
          this.item_List = true
          this.loading.close()

        } else {
          this.loading.close()

        }
      })


    },

    LazyLoadingData(node, resolve) {
      if (node.level === 0) {
        api.getRootPath().then((res) => {
          if (res.data.code == "success") {
            console.log(res.data.data)
            return resolve(res.data.data);
          }
        })
        return resolve([]);
      }
      if (node.level == 1) {
        api.getDrawImage(node.data.path).then((res) => {
          if (res.data.code == "success") {
            if (res.data.data.leaf == true) {
              this.TargetFile = res.data.data.path
              console.log(this.TargetFile)
            }
            return resolve(res.data.data);
          }
        })
        return resolve([]);
      }


    },

    handleNodeClick(data) {
      if (data.leaf == true) {
        this.TargetFile = data.path + data.name
        console.log(data)

      }
    },

    DataProcess(data) {
      if (data == null) return
      // 遍历data
      for (let i = 0; i < data.length; i++) {
        this.tableData.push({})
        this.tableData[i].Start = "[" + data[i].ActStart + "]"
        this.tableData[i].End = "[" + data[i].ActEnd + "]"
        //  this.tableData[i].Start = "[" + data[i].起点像素坐标 + "]"
        // this.tableData[i].End = "[" + data[i].终点像素坐标 + "]"
        this.tableData[i].Pixel = data[i].PixLen
        this.tableData[i].Actual = data[i].Actlen
        this.tableData[i].Hook = data[i].Bent == 1 ? '是' : '否'
      }
      console.log(this.tableData)
    },

    xy2Doc(x, y) {
      return Mx.MxFun.screenCoord2Doc(x, y, 0)
    },

    MxDraw_f5() {
      Mx.MxFun.getCurrentDraw().updateDisplay();
    },

    getCellClass({ row, column }) {

      if (column.property === 'Start') {
        // 将row.Start转换为数组
        let Start = row.Start.replace('[', '').replace(']', '').split(',')

        // 判断是否在加工范围内
        if (this.isPointInRect(Start[0], Start[1]) || Start == "")
          return 'cell_Highlight';
      }
      if (column.property === 'End') {
        // 将row.End转换为数组
        let End = row.End.replace('[', '').replace(']', '').split(',')
        // 判断是否在加工范围内
        if (this.isPointInRect(End[0], End[1]) || End == "")
          return 'cell_Highlight';
      }


      if (column.property === 'Pixel' && (row.Pixel < this.PixelLengthThreshold[0] || row.Pixel > this.PixelLengthThreshold[1])) return 'cell_Highlight';
      if (column.property === 'Actual' && (row.Actual < this.ActualLengthThreshold[0] || row.Actual > this.ActualLengthThreshold[1])) return 'cell_Highlight';



      return '';
    },

    clearAllObject() {

      /** ------------------ */

      this.draw.clearAllObject();
      this.MxDraw_f5();
    },

    tableData_draw_line() {
      this.clearAllObject();
      // 遍历tableData数据
      for (let i = 0; i < this.tableData.length; i++) {
        // 获取起始点
        let Start = this.tableData[i].Start.replace('[', '').replace(']', '').split(',')
        // 获取终点
        let End = this.tableData[i].End.replace('[', '').replace(']', '').split(',')
        // 设置线段的起始点

        // 实例化线段对象line
        let line = new Mx.MxDbLine()
        line.setPoint1(this.xy2Doc(Start[0] * this.Scale_Multiple, Start[1] * this.Scale_Multiple));
        // 设置线段的终点
        line.setPoint2(this.xy2Doc(End[0] * this.Scale_Multiple, End[1] * this.Scale_Multiple));
        // 设置颜色
        line.setColor(0x010101);
        // 将线段对象添加到画布中
        this.draw.addMxEntity(line);
        this.MxDraw_f5();

      }
    },

    async draw_Rect(x1, y1, x2, y2) {
      /** 画一个贴合框画布的矩形 */
      let rect = new Mx.MxDbRect();
      rect.pt1 = this.xy2Doc(x1 * this.Scale_Multiple, y1 * this.Scale_Multiple)

      rect.pt2 = this.xy2Doc(x2 * this.Scale_Multiple, y2 * this.Scale_Multiple)
      rect.setColor(0xFF22)
      this.draw.addMxEntity(rect);

    },

    async draw_line() {

      // 实例化线段对象line
      let line = new Mx.MxDbLine()
      const getPoint = new Mx.MrxDbgUiPrPoint()
      const pt1 = await getPoint.go()
      if (!pt1) return
      // 设置线段的起始点
      line.setPoint1(pt1);
      // 设置颜色
      line.setColor(0xFF22);
      // 动态绘制函数
      getPoint.setUserDraw((currentPoint, worldDrawComment) => {
        // 设置线段第二个点位置
        line.setPoint2(currentPoint);
        // 绘制线段对象
        worldDrawComment.drawCustomEntity(line);
      })
      // 将第二次鼠标点击的位置设置为线段的结束点
      const pt2 = await getPoint.go()
      if (!pt2) return
      line.setPoint2(pt2)
      // 获取控件对象并将线段对象line添加到画布中
      this.draw.addMxEntity(line);
      this.MxDraw_f5();
    },


    isPointInRect(x, y) {

      const x1 = this.ProScope[0];
      const y1 = this.ProScope[1];
      const x2 = this.ProScope[2];
      const y2 = this.ProScope[3];


      // 如果点的X、Y坐标在矩形的X、Y坐标和宽、高的范围内，则点在矩形内
      return !(x >= x1 && x <= x2) && (y >= y1 && y <= y2);
    },

  }



}
</script>


<style>
/* 高亮所有单元格 */
.el-table .cell_Highlight {
  background-color: #f61509ed;
  color: #fff;
  /* 可以更换为你想要的颜色 */
}

.cell_Highlight:hover {

  /* 可以更换为你想要的颜色 */
}

.container {
  position: relative;
}

.left-aligned-button {
  position: absolute;
  left: 0;
  top: 0;
  /* 根据需要调整到合适的垂直位置 */
}

.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}
</style>