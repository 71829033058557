<template>
	<div class="layout-main hHun wHun">
		<Header></Header>
		<div class="tabs_main" :style="{height:bgHeight}">
        <!-- <el-tabs style="height: 100% !important" v-model="activeIndex" type="border-card" closable @tab-click="tabClick" v-if="options.length" @tab-remove="tabRemove">
          <el-tab-pane :key="item.name" v-for="(item, index) in options" :label="item.name" :name="item.route"></el-tab-pane>
          <router-view v-if="isRouterAlive" />
        </el-tabs> -->
		 <router-view v-if="isRouterAlive" />
      </div>
	</div>
</template>

<script>
	import Header from './Header';
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: 'NavMenu',
		provide() {
			return {
				reload: this.reload
			};
		},
		components: {
			Header
		},
		data() {
			return {
				bgHeight:'',
				pkmodule: '', //模块主键
				//isCollapse: true, //导航栏展开收起
				navMenus: [], //导航栏数据
				pkModel: '', //模块编码主键
				isRouterAlive: true
				/* options:['/orderManagement'],
				activeIndex:'dddd' */
			};
		},
		created() {
		  this.bgHeight = document.body.scrollHeight-80 + "px";
		},
		mounted() {
			return;
			console.log(this.$store);
			this.$axios({
				method: 'post',
				url: 'getCurrentLoginUser'
			}).then(res => {
				window.localStorage.setItem('pkcompany', res.data.data.basicuser.pkcompany);
				window.localStorage.setItem('creator', res.data.data.basicuser.creator);
				window.localStorage.setItem('creatorpkdept', res.data.data.basicperson.pkdept);
				window.localStorage.setItem('pkperson', res.data.data.basicuser.pkperson);
			}); //存储当前登陆人公司主键，当前登陆人主键
			this.getpkModule();
			// 刷新时以当前路由做为tab加入tabs
			if (this.$route.path !== '/' && this.$route.path.indexOf('userInfo') == -1) {
				this.$store.commit('add_tabs', {
					route: this.$route.path,
					name: this.$route.name
				});
				this.$store.commit('set_active_index', this.$route.path);
			} else {
				this.$store.commit('add_tabs', {
					route: '/orderManagement', //homePage
					name: '首页'
				});
				this.$store.commit('set_active_index', '/orderManagement'); //homePage
				this.$router.push('/orderManagement'); //homePage
			}
			//调用侧边栏方法
			this.loadNav();
		},
		computed: {
			classObj() {
				return {
					hideSidebar: !this.sidebar.opened,
					openSidebar: this.sidebar.opened,
					withoutAnimation: this.sidebar.withoutAnimation
				};
			},
			...mapGetters(['sidebar']),
			isCollapse() {
				return !this.sidebar.opened;
			},
			options() {
				let aa = this;
				console.log(aa.$store);
				return this.$store.state.options;
			},
			activeIndex: {
				get() {
					return this.$store.state.activeIndex;
				},
				set(val) {
					this.$store.commit('set_active_index', val);
				}
			}
		},
		watch: {
			$route(to) {
				let flag = false; //判断是否页面中是否已经存在该路由下的tab页
				//options记录当前页面中已存在的tab页
				for (let option of this.options) {
					//用名称匹配，如果存在即将对应的tab页设置为active显示桌面前端
					if (option.name === to.name) {
						flag = true;
						this.$store.commit('set_active_index', '/' + to.path.split('/')[1]);
						break;
					}
				}
				//如果不存在，则新增tab页，再将新增的tab页设置为active显示在桌面前端
				if (!flag) {
					this.$store.commit('add_tabs', {
						route: '/' + to.path.split('/')[1],
						name: to.name
					});
					this.$store.commit('set_active_index', '/' + to.path.split('/')[1]);
				}
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(function() {
					this.isRouterAlive = true;
				});
			},
			tabClick(tab) {
				let path = this.activeIndex;
				// 用户详情页的时候，对应了二级路由，需要拼接添加第二级路由
				if (this.activeIndex === '/userInfo') {
					path = this.activeIndex + '/' + this.$store.state.userInfo.name;
				}
				this.$router.push({
					path: path
				});
				this.$axios({
					method: 'post',
					url: 'getpkmoduleByClass',
					params: {
						myclass: tab.name
					}
				}).then(res => {
					localStorage.setItem('pkmodelName', res.data.data.nodecode);
					localStorage.setItem('pkmodel', res.data.data.pkmodule);
					/*        this.$store.commit('findPkmodel', res.data.data.nodecode);
					this.$store.commit('getPkmoduleMain', res.data.data.pkmodule); */
				});
			},
			tabRemove(targetName) {
				// 首页不可删除
				if (targetName == '/orderManagement') { ////homePage
					return;
				}
				this.$store.commit('delete_tabs', targetName);
				if (this.activeIndex === targetName) {
					// 设置当前激活的路由
					if (this.options && this.options.length >= 1) {
						this.$store.commit('set_active_index', this.options[this.options.length - 1].route);
						this.$router.push({
							path: this.activeIndex
						});
					} else {
						this.$router.push({
							path: '/orderManagement' ////homePage
						});
					}
				}
			},
			//默认加载侧边栏方法
			loadNav() {
				this.$axios({
					method: 'post',
					url: 'getCommoduleList',
					params: {
						strsystem: 'tjk'
					}
				}).then(res => {
					for (var a = 0; a < res.data.data.length; a++) {
						for (var b = 0; b < res.data.data[a].children.length; b++) {
							if (res.data.data[a].children[b].pkmodule == 1212 || res.data.data[a].children[b]
								.pkmodule == 1217 || res.data.data[a].children[b].pkmodule == 1216) {
								//当pkmodule为1212 设备生产分析页面时跳转新页面
								res.data.data[a].children[b].mclass = {}; //判断左侧导航栏是否跳转新页面字段
							}
						}
					}
					this.navMenus = res.data.data;
				});
			},
			//获取模块编码方法【按钮权限有用到】
			getpkModule(pkModule) {
				this.pkModel = pkModule;
			}
		}
	};
</script>
<style>
	.layout-main {
		overflow: hidden;
		/* border: 2px solid blue; */
	}
	.tabs_main{
		/* border: 2px solid green; */
		padding: 10px 30px;
	}

	/* .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
		color: #fff;
		background-color: #94B4EB;
		border-right-color: #94B4EB;
		border-left-color: #94B4EB;
		font-size: 12px;
		padding-right: 10px;
		padding-left: 10px;
	}

	.el-tabs__item {
		height: 41px;
		line-height: 40px;
		padding: 0px 12px;
	}

	.el-tabs__header {
		padding: 0px 32px;
	}

	.el-tabs__content {
		background-color: #F2F3F5;
		padding: 10px 32px 5px 32px !important;
	} */
</style>