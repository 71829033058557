
import {
	createApp
} from 'vue'
import Vuex from 'vuex';
import getters from './getters'
import app from './app'
const Vue = createApp()
Vue.use(Vuex);
/**
 * Vuex全局状态管理
 * @param options {Array} 用于渲染tabs的数组
 */
const store = new Vuex.Store({
  modules: {
    app
  },
  getters,
  state: {
    options: [],
    activeIndex: '/tjkcom/layout',
    userInfo: {},
    pkmodel: '' || localStorage.getItem("pkmodelName"), //模块编码, 先从localStorage中获取数据
    pkmodelMain: '' || localStorage.getItem("pkmodel") //模块主键
  },
  mutations: {
    // 添加tabs
    add_tabs(state, data) {
      console.log(data);
      this.state.options.push(data);
    },
    // 删除tabs
    delete_tabs(state, route) {
      let index = 0;
      for (let option of state.options) {
        if (option.route === route) {
          break;
        }
        index++;
      }
      this.state.options.splice(index, 1);
    },
    // 设置当前激活的tab
    set_active_index(state, index) {
      this.state.activeIndex = index;
    },
    // 设置详情信息
    save_detail_info(state, info) {
      this.state.userInfo = info;
    },
    //获取当前所选模块编码
    findPkmodel(state, data) {
      localStorage.setItem('pkmodelName', data); //将传递的数据先保存到localStorage中
      state.pkmodel = data;
    },
    //获取所点击模块主键
    getPkmoduleMain(state, data) {
      localStorage.setItem('pkmodel', data); //将传递的数据先保存到localStorage中
      state.pkmodelMain = data;
    }
  }
});

export default store;
