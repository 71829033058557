import request from '@/utils/request'
export function $axiosPost(url, data) {
	return request({
		url: '/' + url,
		method: 'post',
		headers: {},
		data: data
	})
}
//查询当前登录人
export function requestUserLogin(data) {
	return request({
		url: '/getCurrentUser',
		method: 'post',
		headers: {},
		data: data
	})
}
//查询公司
export function requestCompany(data) {
	return request({
		url: '/qryCompanyByWhere',
		method: 'post',
		headers: {},
		data: data
	})
}
//查询所有方法
export function requestGetList(url, data) {
	return request({
		url: url,
		method: 'post',
		headers: {},
		data: data
	})
}
//保存方法
export function requestPublishSave(url, data) {
	return request({
		url: url,
		method: 'post',
		headers: {},
		data: data
	})
}
//【删除】【启用】【封存】方法
export function requestPublishEvent(url, data) {
	return request({
		url: url,
		method: 'post',
		headers: {},
		data: data
	})
}