import request from '@/utils/request'
export const axiosPost = function $axiosPost(url, data) {
	return request({
		url: '/' + url,
		method: 'post',
		headers: {},
		data: data
	})
}
//测试
// export function $axiosPost(url, data) {
// 	return request({
// 		url: '/' + url,
// 		method: 'post',
// 		headers: {},
// 		data: data
// 	})
// }
// export function $axiosGet(url, data) {
// 	return request({
// 		url: '/' + url,
// 		method: 'post',
// 		headers: {},
// 		data: data
// 	})
// }