<template>
	<div class="header_content wHun">
		<div class="header_title hHun fl">图像识别处理系统</div>
		<div class="headerNav hHun fl">
			<div class="disL">
				<!-- :default-active="activeIndex2" -->
				<el-menu :ellipsis='false' :router="true" :default-active="this.$route.path" class="el-menu-demo"
					mode="horizontal" @select="handleSelect">
					<el-sub-menu index="1">
						<template #title>基础设置</template>
						<el-menu-item index="1-1" route="/User">账号基础设置</el-menu-item>
						<el-menu-item index="1-2" route="/Company">公司基础设置</el-menu-item>
						<el-menu-item index="1-3" route="/Equmient">设备基础设置</el-menu-item>
					</el-sub-menu>
					<el-menu-item index="2" route="/shareSpace">基础方案设置</el-menu-item>
					<el-menu-item index="3" route="/Filesys">格式转换</el-menu-item>
					<el-menu-item index="4" route="/Distinguish">图像识别</el-menu-item>
				</el-menu>
			</div>
		</div>
		<div class='header_right disL fr'>
			<div class="header_r hHun fr marR20">
				<div class="heder_ricon ac wFive hHun fr"><span class="icon_back bgsHun iconHeader"
						@click="backLogin"></span></div>
			</div>
			<div class="hHun fr">
				<div class="ac wHun hHun fl">
					<span class="headerName">{{ userName }}</span>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		requestUserLogin
	} from '@/api/publish/pub'
	import {
		requestUnLogin
	} from '@/api/login/login'
	export default {
		inject: ['reload'],
		data() {
			return {
				isCollapse: false, //导航栏展开收起
				dialogFormVisible: false,
				form: {},
				userName: '', //当前登陆人
				company: '', //公司
				pkbasicuser: '', //人员主键
				pkperson: '',
				pkcompany: '',
				messagelength: 0, //消息数量
				messagedata: '',
				timer: null,
				//isopen: false
				activeIndex: '',
				activeIndex2: '2'
			};
		},
		components: {
			/* sidebarbutton, */
		},
		computed: {
			/* ...mapGetters(["sidebar"]), */
		},
		mounted() {
			this.user();
			this.timer = setInterval(() => {
				this.user();
			}, 1000 * 60 * 2);
		},
		methods: {
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},
			user() {
				requestUserLogin('').then(response => {
					this.userName = response.data.data.basicuser.username;
				})
			},
			toggleSideBar() {
				this.$store.dispatch('toggleSideBar');
			},
			backLogin() {
				console.log(1);
				this.$confirm('您将退出系统, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						localStorage.clear(); //清除local
						requestUnLogin('').then(response => {
							location.reload();
							this.$router.replace({
								path: '/login'
							});
						})
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '用户取消了退出登录操作！'
						});
					});
			}
		}
	};
</script>
<style>
	.el-menu.el-menu--horizontal {
		border-bottom: solid 0px transparent !important;
	}
</style>
<style scoped>
	.header_content {
		height: 72px;
		background: #FFFFFF;
		line-height: 72px;
		padding: 0px 35px;
		font-family: PingFangSemibold;
	}

	.header_title {
		/* width: 250px; */
		font-size: 19px;
		/* font-family: FZXINGHEIS-B--GB1-0, FZXINGHEIS-B--GB1; */
		font-weight: normal;
		font-size: 42px;
		color: #1C1C28;
		letter-spacing: 1px;
		text-shadow: 0px 2px 4px rgba(255, 255, 255, 0.08);
		margin-right: 116px;
		font-family: JT;
	}

	.header_right {
		/* border: 2px solid yellow; */
		/* width: 200px; */
		position: absolute;
		top: 0px;
		right: 30px;
		height: 72px;
		line-height: 72px;
	}

	.headerNav {
		/* border: 2px solid red; */
		/* width: 70%;
		min-width:600px; */
		text-align: center;
		/* padding-top: 7px; */
	}

	.header_r {
		/* border: 1px solid red; */
		/* width: 96px; */
		line-height: 89px;
	}

	.heder_ricon {
		width: 48%;
		cursor: pointer;
	}

	.iconHeader {
		/* border: 1px solid red; */
		display: inline-block;
		width: 25px;
		height: 25px;
	}

	.icon_back {
		background-image: url(../../assets/img/header/icon_back.png);
	}

	/* .icon_tz {
		background-image: url(../../assets/img/header/icon_tz.png);
	} */

	.headerName {
		font-size: 16px;
		/* font-family: PingFangSC-Regular, PingFang SC; */
		font-weight: 400;
		/* color: #ffffff; */
		letter-spacing: 1px;
		/* border: 1px solid red; */
		font-family: PingFangSemibold;
	}

	.icon_tx {
		margin-right: 8px;
		width: 24px;
		height: 24px;
		background: #d8d8d8;
		border-radius: 12px;
		vertical-align: middle;
	}
</style>