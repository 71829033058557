import request from '@/utils/request'
//登录
export function requestLogin(data) {
	return request({
		url: 'userLogin',
		method: 'post',
		headers: {
			// "Content-Type": 'application/x-www-form-urlencoded;charset=UTF-8'
		},
		data: data
	})
}
//退出登录
export function requestUnLogin(data) {
	return request({
		url: 'logOutSys',
		method: 'post',
		headers: {},
		data: data
	})
}