import {
  createApp
} from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from "element-plus/es/locale/lang/zh-cn";
import './assets/css/common.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'



const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
import {
  openLoading,
  closeLoading
} from '../src/assets/js/commonJs/resetLoading';
import {
  axiosPost,
  axiosGet,
} from '../src/api/api'
import resetMessage from '../src/assets/js/commonJs/resetMessage';

app.config.globalProperties.baseUrl = 'http://47.105.43.195:8886/'

app.config.globalProperties.$axiosPost = axiosPost;
app.config.globalProperties.$axiosGet = axiosGet;
app.config.globalProperties.$resetmessage = resetMessage;
app.config.globalProperties.$openLoading = openLoading;
app.config.globalProperties.$closeLoading = closeLoading;
//app.use(router).mount('#app')
app.use(ElementPlus, {
  locale: zhCn
})
app.use(router)
app.use(store)
app.mount('#app')

router.beforeEach((to, from, next) => {
  /*如果本地 存在 token 则 不允许直接跳转到登录页面*/
  if (to.fullPath == "/login") {
    if (localStorage.getItem('token')) {
      next({
        path: "/homePage"
      });
    } else {
      next();
    }
  }
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (localStorage.getItem('token')) { //判断本地是否存在access_token
      next();
    } else {
      if (to.path === '/login') {
        next();
        Vue.prototype.$message.error("您需要登陆")
      } else {
        next({
          path: '/login'
        });
        Vue.prototype.$message.error('您需要登陆');
      }
    }
  } else {
    next();
  }
  // next();
});

 
 
//createApp(App).use(router).mount('#app')