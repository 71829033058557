import {
	createRouter,
	createWebHistory
} from 'vue-router';
import login from '../views/login/index.vue'
import homePage from '../views/main/Layout.vue'
import User from '../views/basic/basicUser.vue'
import Company from '../views/basic/basicCompany.vue'
import Equmient from '../views/basic/basicEqumient.vue'
import Filesys from '../views/Format/basicFilesys.vue'
import Distinguish from '../views/Format/basicDistinguish.vue'
import shareSpace from '@/views/Format/shareSpace.vue';


const routes = [{
	path: '/',
	redirect: '/login',
},
{
	path: '/login',
	component: login,
	hidden: true
}, {
	path: '/homePage',
	name: '首页',
	component: homePage,
	meta: { //表示进入这个路由是需要登录的// 添加该字段
		requireAuth: true,
	},
	children: [{
		path: '/User',
		component: User,
		name: '账户基础设置'
	}, {
		path: '/Company',
		component: Company,
		name: '公司基础设置'
	}, {
		path: '/Equmient',
		component: Equmient,
		name: '设备基础设置'
	}, {
		path: '/Filesys',
		component: Filesys,
		name: '格式转换'
	}, {
		path: '/Distinguish',
		component: Distinguish,
		name: '图片识别'
	}, {
		path: '/shareSpace',
		component: shareSpace,
		name: '测试'
	}
	],
}

]


// 创建router实例
const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;