<template>
	<div class="grid-content bg-purple userMain hHun">
		<div class="tabName">账户基础设置</div>
		<div class="btnList">
			<el-button type="primary" size="small" plain @click="getButtonState('add')">增加</el-button>
			<el-button type="success" size="small" plain @click="getButtonState('edit')">修改</el-button>
			<el-button type="danger" size="small" plain @click="getButtonState('delete')">删除</el-button>
			<el-button type="warning" size="small" plain @click="getButtonState('enable')">启用</el-button>
			<el-button type="info" size="small" plain @click="getButtonState('sealup')">封存</el-button>
			<el-button size="small" color="#626aef" plain @click="getButtonState('enableEdit')">启用修改</el-button>
			<div class="disL wFive hHun fr ar pageContent">
				<my-pages :total="total" :page-size="pageSize" @handleSizeChangeSub="handleSizeChangeFun"
					@handleCurrentChangeSub="handleCurrentChangeFun"></my-pages>
			</div>
		</div>

		<div class="tableCon" :style="{height:bgHeight}">
			<el-table highlight-current-row stripe height="100%" :data="tableData" ref="multipleTable"
				@row-click="rowClick" :cell-style="{ 'text-align': 'center'}"
				:header-cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange"
				@row-dblclick="rowDbClick">
				<el-table-column type="index"></el-table-column>
				<el-table-column type="selection" width="30"></el-table-column>
				<el-table-column prop="statename" label="状态">
					<el-table-column prop="statename" width="150">
						<template #header slot-scope="scope">
							<el-select v-model="searchState" placeholder="状态" clearable @change="getTableData">
								<el-option v-for="item in filterStatesList" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="account" label="账号">
					<el-table-column prop="account">
						<template #header slot-scope="scope">
							<el-input clearable v-model="searchAccount" placeholder="" />
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="username" label="人员姓名">
					<el-table-column prop="username">
						<template #header slot-scope="scope">
							<el-input clearable v-model="searchName" @change="getTableData" placeholder="" />
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="phone" label="电话"></el-table-column>
				<el-table-column prop="comname" width="300" label="所属公司">
					<el-table-column prop="comname" width="300">
						<template #header slot-scope="scope">
							<el-select v-model="searchCompany" clearable filterable @change="getTableData">
								<el-option v-for="item in companyList" :key="item.pkcompany" :label="item.comname"
									:value="item.pkcompany"></el-option>
							</el-select>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="password" label="有效期" :formatter="formatDate" width="220"></el-table-column>
			</el-table>
		</div>
		<el-dialog :close-on-click-modal="false" :title="titleState" v-model="dialogFormVisible" width="650px"
			v-if="dialogFormVisible">
			<el-form class="userform" label-width="110px" label-position="left" :rules="rules" :model="form"
				:inline="true" ref="form">
				<el-row type="flex" class="row-bg">
					<el-col :span="24">
						<el-form-item label="所属公司" prop="pkcompany">
							<el-select v-model="form.pkcompany" clearable filterable style="width: 400px !important;">
								<el-option v-for="item in companyList" :key="item.pkcompany" :label="item.comname"
									:value="item.pkcompany"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg">
					<el-col :span="12">
						<el-form-item label="员工姓名" prop="username"><el-input v-model="form.username"
								autocomplete="on"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="是否Tjk管理员" prop="iftjkmanage">
							<el-switch v-model="form.iftjkmanage" active-color="#13ce66" inactive-color="#ff4949"
								active-value="1" inactive-value="0" :disabled="ifTjkMan"></el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg">
					<el-col :span="12">
						<el-form-item label="账号" prop="account"><el-input v-model="form.account"
								autocomplete="off"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="密码" prop="password">
							<el-input placeholder="请输入密码" v-model="form.password" autocomplete="off"
								auto-complete="new-password"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg">
					<el-col :span="12">
						<el-form-item label="手机号" prop="phone"><el-input v-model="form.phone"
								autocomplete="on"></el-input></el-form-item>
					</el-col>

				</el-row>
				<el-row type="flex" class="row-bg">
					<el-col :span="24">
						<div class="disL signT"><span class="dateImportant">*</span></div>
						<el-form-item label="有效日期" prop="Mybeginvalidate">
							<div class="block">
								<el-date-picker v-model="Mybeginvalidate" type="daterange" range-separator="至"
									start-placeholder="开始日期" end-placeholder="结束日期" format="YYYY-MM-DD"
									value-format="YYYY-MM-DD" />
							</div>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer ar">
				<el-button @click="callOf('form')">取 消</el-button>
				<el-button type="primary" @click="submitForm('form')" v-show="ifSave">保 存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		$axiosPost,
	} from '@/api/publish/pub'
	import {
		myPages,
	} from '../../components/common';

	import qs from 'qs';
	export default {
		name: 'basicUser',
		components: {
			myPages,
		},
		mounted() {
			this.bgHeight = document.body.scrollHeight - 190 + "px";
			this.getTableData();
			this.getPersonCompany();
		},
		data() {
			return {
				bgHeight: '',
				titleState: '添加', //弹框状态提示
				titleRoleState: '管理员', //添加角色弹框
				checkIndex: 0, //当前选中行的个数
				pkBasicuserStr: '', //存放当前选中行的主键，以‘，’隔开【删除，启用，封存】方法中使用
				tableData: [], //存放table数据
				editTable: {}, //存放修改后表单的数据
				editSta: '', //启用修改状态
				total: 0, //默认数据总数
				pageSize: 20, //每页的数据条数
				currentPage: 1, //默认开始页面
				dialogFormVisible: false,
				Mybeginvalidate: [], //存放有效期的变量 （作用：将有效期格式拆分为后台需要的形式）
				saveUrl: '', //是添加保存还是修改保存
				form: {
					//表单
					pkbasicuser: '', //主键
					pkcompany: '', //所属公司
					account: '', //账号
					password: '', //密码
					phone: '', //手机号
					iftjkmanage: '', //是否tjk管理员
					pkperson: '', //员工编号
					username: '', //用户名
					beginvalidate: '', //有效期开始日期
					endvalidate: '', //有效期结束日期
				},
				rules: {
					//表单验证规则
					account: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}]
				},
				pkUserq: '', //当前登录人
				ifTjkMan: true, //是否是TJK管理员
				searchAccount: '',
				searchName: '',
				searchCompany: '',
				searchState: '',
				filterStatesList: [{
						value: '1',
						label: '自由'
					},
					{
						value: '2',
						label: '启用'
					},
					{
						value: '3',
						label: '封存'
					}
				],
				companyList: [], //公司下拉框数据
				ifSave: true, //是否显示保存按钮
			};
		},
		methods: {
			//此单据的按钮及方法
			getButtonState(str) {
				switch (str) {
					case 'add':
						this.clearForm('form');
						this.dialogFormVisible = true; //打开弹窗
						this.titleState = '添加';
						this.saveUrl = 'addBasicUser'; //调用添加方法
						break;
					case 'delete':
						this.ButtonOperation(1, 'deleteBasicUser');
						break;
					case 'edit':
						this.edit();
						break;
					case 'enable':
						this.ButtonOperation(2, 'OKBasicUser');
						break;
					case 'sealup':
						this.ButtonOperation(3, 'InvalidBasicUser');
						break;
					case 'enableEdit':
						this.editSta = 'enableEdit';
						this.edit('enableEdit');
						break;
				}
			},
			//查询所有
			getTableData() {
				let data = {
					pageSize: this.pageSize, //当前分页条数
					pageNo: this.currentPage, //当前页
					account: this.searchAccount,
					username: this.searchName,
					pkcompany: this.searchCompany,
					states: this.searchState
				}
				this.$axiosPost('qryBasicUserBypage', data).then(response => {
					this.tableData = response.data.data.list;
					this.total = response.data.data.totalRecords;
				})
			},
			//单击行方法
			rowClick(row, column, event) {
				this.$refs.multipleTable.toggleRowSelection(row); //点击选中
			},
			//双击行操作
			rowDbClick(row, column, event) {
				this.$refs.multipleTable.clearSelection(); //清空选中行
				this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
				this.dialogFormVisible = true; //打开弹窗
				this.edit('DbClick'); //调用修改方法
			},
			//【修改】【启用修改】【查看】方法
			edit(clickStates) {
				this.saveUrl = 'editBasicUser'; //调用修改方法
				if (this.splicingPk(100, clickStates) === true) {
					this.editTable = this.multipleSelection[0];
					if (this.editSta === 'enableEdit') {
						this.titleState = '启用修改';
						this.ifSave = true;
					} else {
						this.editTable.states != 1 ? ((this.titleState = '查看'), (this.ifSave = false)) : ((this
							.titleState = '修改'), (this.ifSave = true));
					}
					this.dialogFormVisible = true;
					this.form.pkcompany = this.editTable.pkcompany;
					this.form.pkbasicuser = this.editTable.pkbasicuser;
					this.form.account = this.editTable.account; //账号
					this.form.password = this.editTable.password; //密码
					this.form.phone = this.editTable.phone; //手机号
					if (this.editTable.iftjkmanage === 1) {
						this.form.iftjkmanage = '1'; //是否tjk管理员
					} else {
						this.form.iftjkmanage = '0'; //是否tjk管理员
					}
					this.form.username = this.editTable.username; //用户名
					this.Mybeginvalidate = [this.editTable.beginvalidate, this.editTable.endvalidate];
				}
			},
			//【删除】【启用】【封存】【提交】方法
			ButtonOperation(buttonState, buttonUrl) {
				if (this.splicingPk(buttonState) !== false) {
					let data = {
						pkusers: this.pkBasicuserStr,
					}
					$axiosPost(buttonUrl, data).then(res => {
						if (res.data.code === 'success') {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getTableData();
						} else {
							this.$notify.error({
								title: '错误',
								message: res.data.msg
							});
						}
					})
				}
			},
			//将选中行的主键拼接成以‘，’隔开的方法
			splicingPk(ButtonState, editStates) {
				//按钮状态,修改状态【修改与双击】
				let checkData = this.multipleSelection; //获取到选中行的值
				let pkBasicUserThis = ''; //以‘，’分开的主键
				if (this.checkIndex === 0) {
					this.$message({
						message: '请选择数据进行此操作！',
						type: 'warning'
					});
					return false;
				}
				//判断是否为【修改】
				if (ButtonState === 100) {
					//修改状态
					if (this.checkIndex != 1) {
						this.$message.warning('仅允许对一条数据进行查看！');
						return false;
					}
				}
				//判断是否为【启用修改】
				if (editStates === 'enableEdit') {
					//启用修改判断(仅允许修改状态为启用的单据)
					if (checkData[0].states !== 2) {
						this.$message.warning('仅允许修改状态为【启用】的单据！');
						return false;
					}
				}
				for (var i = 0; i < checkData.length; i++) {
					if (ButtonState !== 3 && ButtonState !== 2 && editStates != 'DbClick' && editStates != 'enableEdit') {
						//封存状态
						if (checkData[i].states !== 1) {
							this.$message.warning('只有自由状态才可执行此操作！');
							return false;
						}
					}
					pkBasicUserThis += checkData[i].pkbasicuser + ',';
				}
				pkBasicUserThis = pkBasicUserThis.substring(0, pkBasicUserThis.lastIndexOf(','));
				this.pkBasicuserStr = pkBasicUserThis;
				return true;
			},
			//表单提交方法（保存方法）
			submitForm(formName) {
				if (this.editTable.states !== 1 && this.editTable.states !== undefined && this.editSta !== 'enableEdit') {
					this.$message.warning('只有自由状态才可修改！');
					return false;
				}
				if (this.Mybeginvalidate !== null && this.Mybeginvalidate.length !== 2) {
					this.$message.warning('请选择有效期！');
					return false;
				}
				if (this.Mybeginvalidate === null) {
					this.$message.warning('请选择有效期！');
					return false;
				}
				this.$refs[formName].validate(valid => {
					this.verificationEditCom();
					if (valid) {
						const tempData = Object.assign({}, this.form);
						$axiosPost(this.saveUrl, qs.stringify(this.form)).then(res => {
							if (res.data.code === 'success') {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.dialogFormVisible = false;
								this.$refs[formName].resetFields();
								this.getTableData();
							} else {
								this.$notify.error({
									title: '错误',
									message: res.data.msg
								});
							}
							this.editSta = ''; //将【修改】【启用修改状态清空】
							this.form.states = ''; //将状态清空
						})
					}
				});
			},
			//关闭弹框方法
			callOf(formName) {
				this.$nextTick(() => {
					this.$refs[formName].resetFields();
					this.dialogFormVisible = false;
				});
			},
			//清空form表单方法
			clearForm(formName) {
				this.$nextTick(() => {
					//this.form. = {};
					this.form.pkbasicuser = ''; //主键
					this.form.pkcompany = ''; //所属公司
					this.form.account = ''; //账号
					this.form.password = ''; //密码
					this.form.phone = '', //手机号
						this.form.iftjkmanage = ''; //是否tjk管理员
					this.form.username = ''; //用户名
					//this.form.def10 = 3; //跳转页
					this.editTable = '';
					this.Mybeginvalidate = []; //有效期
				});
			},
			//查询当前登录人是否为tjk管理员
			getIfTjk() {
				$axiosPost('getCurrentUser', '').then(res => {
					this.pkUserq = res.data.data.basicuser.pkbasicuser;
					if (res.data.data.basicuser.iftjkmanage === 1) {
						this.ifTjkMan = false;
					}
				})
			},
			//查询所有公司
			getPersonCompany() {
				$axiosPost('qryCompanyByWhere', '').then(response => {
					this.companyList = response.data.data;
				})
				this.getIfTjk();
			},
			//修改验证多选框是否发生改变的方法
			verificationEditCom() {
				this.form.beginvalidate = this.Mybeginvalidate[0];
				this.form.endvalidate = this.Mybeginvalidate[1];
			},
			//获取当前选中行数据方法
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.checkIndex = val.length;
			},
			//分页相关【下一页】操作
			handleSizeChangeFun(v) {
				this.pageSize = v;
				this.getTableData(); //更新列表
			},
			//分页相关【一页几条】操作
			handleCurrentChangeFun(v) {
				this.currentPage = v; //当前页
				this.getTableData(); //更新列表
			},
			//显示有效期方法
			formatDate(row, column, cellValue) {
				let beginvalidate = row.beginvalidate;
				let endvalidate = row.endvalidate;
				let effectiveDate = beginvalidate.substring(0, 10) + '—' + endvalidate.substring(0, 10);
				return effectiveDate;
			},
		}
	};
</script>
<style>
	
</style>
