import {
  Loading
} from 'element-plus'
let loadingInstance = null
export const openLoading = function() {
  loadingInstance = Loading.service({
    text: '拼命加载中',
    'background': 'rgba(0, 0, 0, 0.6)'
  })
}
export const closeLoading = function() {
  if (loadingInstance) {
    loadingInstance.close()
  }
}
