<template>
    <div class="shareSpace">
        <div class="shareSpace-header">
            <div class="shareSpace-header-left">
                <span>基础方案设置</span>

            </div>
        </div>
    </div>
</template>

<script>


export default {
    components: {

    },

    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }



}
</script>