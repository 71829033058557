<template>
	<div class="grid-content bg-purple userMain hHun">
		<div class="tabName">设备基础设置</div>
		<div class="btnList">
			<el-button type="primary" size="small" plain @click="getButtonState('add')">增加</el-button>
			<el-button type="success" size="small" plain @click="getButtonState('edit')">修改</el-button>
			<el-button type="danger" size="small" plain @click="getButtonState('delete')">删除</el-button>
			<div class="disL wFive hHun fr ar pageContent">
				<my-pages :total="total" :page-size="pageSize" @handleSizeChangeSub="handleSizeChangeFun"
					@handleCurrentChangeSub="handleCurrentChangeFun"></my-pages>
			</div>
		</div>

		<div class="tableCon" :style="{height:bgHeight}">
			<el-table highlight-current-row stripe height="100%" :data="tableData" ref="multipleTable"
				@row-click="rowClick" :cell-style="{ 'text-align': 'center'}"
				:header-cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange"
				@row-dblclick="rowDbClick">
				<el-table-column type="index"></el-table-column>
				<el-table-column type="selection" width="30"></el-table-column>
				<el-table-column prop="equcode" label="设备编码" width="150">
					<el-table-column prop="equcode" width="150">
						<template #header>
							<el-input clearable v-model="searchEqucode" placeholder="" @change="getTableData" />
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="equname" label="设备名称" width="150">
					<el-table-column prop="equname" width="150">
						<template #header>
							<el-input clearable v-model="searchEquname" @change="getTableData" placeholder="" />
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="xinghao" label="设备型号" width="150">
					<el-table-column prop="xinghao" width="150">
						<template #header>
							<el-input clearable v-model="searchXinghao" @change="getTableData" placeholder="" />
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="ipaddress" label="ip地址" width="180"></el-table-column>
				<el-table-column prop="isinteractive" label="是否交互" width="150">
					<el-table-column prop="isinteractive" width="150">
						<template #header slot-scope="scope">
							<el-select v-model="searchIsinteractive" placeholder="是否交互" clearable
								@change="getTableData">
								<el-option key="0" label="否" value="0"></el-option>
								<el-option key="1" label="是" value="1"></el-option>
							</el-select>
						</template>
						<template #default="{row,$index}">
							<span v-if="row.isinteractive===0">否</span>
							<span v-else>是</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="way" label="交互方式" width="150"></el-table-column>
				<el-table-column prop="hmixdiameter" label="横筋直径范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.hmixdiameter}}-{{row.hmaxdiameter}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="纵筋直径范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.zmindiameter}}-{{row.zmaxdiameter}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="横筋长度范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.hmixlong}}-{{row.hmaxlong}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="纵筋长度范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.zminlong}}-{{row.zmaxlong}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="横筋抽头范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.hmintou}}-{{row.hmaxtou}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="横筋留尾范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.hminwei}}-{{row.hmaxwei}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="纵筋抽头范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.zmintou}}-{{row.zmaxtou}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="纵筋留尾范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.zminwei}}-{{row.zmaxwei}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="横筋间距范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.hmixspace}}-{{row.hmaxspace}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="纵筋间距范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.zminspace}}-{{row.zmaxdiameter}}</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog :close-on-click-modal="false" :title="titleState" v-model="dialogFormVisible" width="900px"
			v-if="dialogFormVisible">
			<el-form class="userform" label-width="110px" label-position="left" :rules="rules" :model="form"
				:inline="true" ref="form">
				<el-row type="flex" class="row-bg">
					<el-col :span="8">
						<el-form-item label="设备编码" prop="equcode"><el-input v-model="form.equcode"
								autocomplete="on"></el-input></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="设备名称" prop="equname"><el-input v-model="form.equname"
								autocomplete="on"></el-input></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="设备型号" prop="xinghao"><el-input v-model="form.xinghao"
								autocomplete="on"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg">
					<el-col :span="8">
						<el-form-item label="ip地址" prop="ipaddress"><el-input v-model="form.ipaddress"
								autocomplete="on"></el-input></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="是否交互" prop="isinteractive">
							<el-select style="width: 160px !important;" v-model="form.isinteractive" placeholder="是否交互">
								<el-option key="0" label="否" value="0"></el-option>
								<el-option key="1" label="是" value="1"></el-option>
							</el-select></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="交互方式" prop="way"><el-input v-model="form.way"
								autocomplete="on"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg">
					<el-col :span="12">
						<el-form-item label="横筋直径范围" prop="hmixdiameter">
							<el-input v-model="form.hmixdiameter" autocomplete="on" style="width: 100px !important;"
								placeholder="最小值"></el-input>
							<span class="disL toCon">-</span>
							<el-input v-model="form.hmaxdiameter" autocomplete="on" style="width: 100px !important;"
								placeholder="最大值"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="纵筋直径范围" prop="zmindiameter">
							<el-input v-model="form.zmindiameter" autocomplete="on" style="width: 100px !important;"
								placeholder="最小值"></el-input>
							<span class="disL toCon">-</span>
							<el-input v-model="form.zmaxdiameter" autocomplete="on" style="width: 100px !important;"
								placeholder="最大值"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg">
					<el-col :span="12">
						<el-form-item label="横筋长度范围" prop="hmixlong">
							<el-input v-model="form.hmixlong" autocomplete="on" style="width: 100px !important;"
								placeholder="最小值"></el-input>
							<span class="disL toCon">-</span>
							<el-input v-model="form.hmaxlong" autocomplete="on" style="width: 100px !important;"
								placeholder="最大值"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="纵筋长度范围" prop="zminlong">
							<el-input v-model="form.zminlong" autocomplete="on" style="width: 100px !important;"
								placeholder="最小值"></el-input>
							<span class="disL toCon">-</span>
							<el-input v-model="form.zmaxlong" autocomplete="on" style="width: 100px !important;"
								placeholder="最大值"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg">
					<el-col :span="12">
						<el-form-item label="横筋抽头范围" prop="hmintou">
							<el-input v-model="form.hmintou" autocomplete="on" style="width: 100px !important;"
								placeholder="最小值"></el-input>
							<span class="disL toCon">-</span>
							<el-input v-model="form.hmaxtou" autocomplete="on" style="width: 100px !important;"
								placeholder="最大值"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="纵筋抽头范围" prop="zmintou">
							<el-input v-model="form.zmintou" autocomplete="on" style="width: 100px !important;"
								placeholder="最小值"></el-input>
							<span class="disL toCon">-</span>
							<el-input v-model="form.zmaxtou" autocomplete="on" style="width: 100px !important;"
								placeholder="最大值"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg">
					<el-col :span="12">
						<el-form-item label="横筋留尾范围" prop="hminwei">
							<el-input v-model="form.hminwei" autocomplete="on" style="width: 100px !important;"
								placeholder="最小值"></el-input>
							<span class="disL toCon">-</span>
							<el-input v-model="form.hmaxwei" autocomplete="on" style="width: 100px !important;"
								placeholder="最大值"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="纵筋留尾范围" prop="zminwei">
							<el-input v-model="form.zminwei" autocomplete="on" style="width: 100px !important;"
								placeholder="最小值"></el-input>
							<span class="disL toCon">-</span>
							<el-input v-model="form.zmaxwei" autocomplete="on" style="width: 100px !important;"
								placeholder="最大值"></el-input>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row type="flex" class="row-bg">
					<el-col :span="12">
						<el-form-item label="横筋间距范围" prop="hmixspace">
							<el-input v-model="form.hmixspace" autocomplete="on" style="width: 100px !important;"
								placeholder="最小值"></el-input>
							<span class="disL toCon">-</span>
							<el-input v-model="form.hmaxspace" autocomplete="on" style="width: 100px !important;"
								placeholder="最大值"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="纵筋间距范围" prop="zminspace">
							<el-input v-model="form.zminspace" autocomplete="on" style="width: 100px !important;"
								placeholder="最小值"></el-input>
							<span class="disL toCon">-</span>
							<el-input v-model="form.zmaxdiameter" autocomplete="on" style="width: 100px !important;"
								placeholder="最大值"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer ar">
				<el-button @click="callOf('form')">取 消</el-button>
				<el-button type="primary" @click="submitForm('form')" v-show="ifSave">保 存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		$axiosPost,
	} from '@/api/publish/pub'
	import {
		myPages,
	} from '../../components/common';

	import qs from 'qs';
	export default {
		name: 'basicUser',
		components: {
			myPages,
		},
		mounted() {
			this.bgHeight = document.body.scrollHeight - 190 + "px";
			this.getTableData();
		},
		data() {
			return {
				bgHeight: '',
				titleState: '添加', //弹框状态提示
				titleRoleState: '管理员', //添加角色弹框
				checkIndex: 0, //当前选中行的个数
				pkStr: '', //存放当前选中行的主键，以‘，’隔开【删除，启用，封存】方法中使用
				tableData: [], //存放table数据
				editTable: {}, //存放修改后表单的数据
				editSta: '', //启用修改状态
				total: 0, //默认数据总数
				pageSize: 20, //每页的数据条数
				currentPage: 1, //默认开始页面
				dialogFormVisible: false,
				Mybeginvalidate: [], //存放有效期的变量 （作用：将有效期格式拆分为后台需要的形式）
				saveUrl: '', //是添加保存还是修改保存
				form: {
					pkequ: '',
					equcode: '',
					equname: '',
					xinghao: '',
					hmaxdiameter: '',
					hmaxlong: '',
					hmaxspace: '',
					hmaxtou: '',
					hmaxwei: '',
					hmintou: '',
					hminwei: '',
					hmixdiameter: '',
					hmixlong: '',
					hmixspace: '',
					ipaddress: '',
					isinteractive: '',
					way: '',
					zmaxdiameter: '',
					zmaxlong: '',
					zmaxspace: '',
					zmaxtou: '',
					zmaxwei: '',
					zmindiameter: '',
					zminlong: '',
					zminspace: '',
					zmintou: '',
					zminwei: '',
				},
				rules: {
					//表单验证规则
					equcode: [{
						required: true,
						message: '请输入设备编码',
						trigger: 'blur'
					}],
					equname: [{
						required: true,
						message: '请输入设备名称',
						trigger: 'blur'
					}],
					xinghao: [{
						required: true,
						message: '请输入设备型号',
						trigger: 'blur'
					}]
				},
				searchEqucode: '',
				searchEquname: '',
				searchXinghao: '',
				searchIsinteractive: '',
				ifSave: true, //是否显示保存按钮
			};
		},
		methods: {
			//此单据的按钮及方法
			getButtonState(str) {
				switch (str) {
					case 'add':
						this.clearForm('form');
						this.dialogFormVisible = true; //打开弹窗
						this.titleState = '添加';
						this.saveUrl = 'saveMequipment'; //调用添加方法
						break;
					case 'delete':
						this.ButtonOperation(1, 'delMequipment');
						break;
					case 'edit':
						this.edit();
						break;
				}
			},
			//查询所有
			getTableData() {
				let data = {
					pageSize: this.pageSize, //当前分页条数
					pageNo: this.currentPage, //当前页
					equcode: this.searchEqucode,
					equname: this.searchEquname,
					xinghao: this.searchXinghao,
					isinteractive: this.searchIsinteractive,
				}
				this.$axiosPost('qureyMequipment', data).then(response => {
					this.tableData = response.data.data.list;
					this.total = response.data.data.totalRecords;
				})
			},
			//单击行方法
			rowClick(row, column, event) {
				this.$refs.multipleTable.clearSelection(); //清空选中行
				this.$refs.multipleTable.toggleRowSelection(row); //点击选中
			},
			//双击行操作
			rowDbClick(row, column, event) {
				this.$refs.multipleTable.clearSelection(); //清空选中行
				this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
				this.dialogFormVisible = true; //打开弹窗
				this.editSta = 'DbClick'
				this.edit('DbClick'); //调用修改方法
			},
			//【修改】【启用修改】【查看】方法
			edit(clickStates) {
				this.saveUrl = 'updateMequipment'; //调用修改方法
				this.titleState = '修改';
				if (this.splicingPk(100, clickStates) === true) {
					this.editTable = this.multipleSelection[0];
					this.dialogFormVisible = true;
					this.form.pkequ = this.editTable.pkequ;
					this.form.equcode = this.editTable.equcode;
					this.form.equname = this.editTable.equname;
					this.form.xinghao = this.editTable.xinghao;
					this.form.hmaxdiameter = this.editTable.hmaxdiameter;
					this.form.hmaxlong = this.editTable.hmaxlong;
					this.form.hmaxspace = this.editTable.hmaxspace;
					this.form.hmaxtou = this.editTable.hmaxtou;
					this.form.hmaxwei = this.editTable.hmaxwei;
					this.form.hmintou = this.editTable.hmintou;
					this.form.hminwei = this.editTable.hminwei;
					this.form.hmixdiameter = this.editTable.hmixdiameter;
					this.form.hmixlong = this.editTable.hmixlong;
					this.form.hmixspace = this.editTable.hmixspace;
					this.form.ipaddress = this.editTable.ipaddress;
					this.form.isinteractive = (this.editTable.isinteractive).toString();
					this.form.way = this.editTable.way;
					this.form.zmaxdiameter = this.editTable.zmaxdiameter;
					this.form.zmaxlong = this.editTable.zmaxlong;
					this.form.zmaxspace = this.editTable.zmaxspace;
					this.form.zmaxtou = this.editTable.zmaxtou;
					this.form.zmaxwei = this.editTable.zmaxwei;
					this.form.zmindiameter = this.editTable.zmindiameter;
					this.form.zminlong = this.editTable.zminlong;
					this.form.zminspace = this.editTable.zminspace;
					this.form.zmintou = this.editTable.zmintou;
					this.form.zminwei = this.editTable.zminwei;
				}
			},
			//【删除】【启用】【封存】【提交】方法
			ButtonOperation(buttonState, buttonUrl) {
				if (this.splicingPk(buttonState) !== false) {
					let data = {
						pkequ: this.pkStr,
					}
					$axiosPost(buttonUrl, data).then(res => {
						if (res.data.code === 'success') {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getTableData();
						} else {
							this.$notify.error({
								title: '错误',
								message: res.data.msg
							});
						}
					})
				}
			},
			//将选中行的主键拼接成以‘，’隔开的方法
			splicingPk(ButtonState, editStates) {
				//按钮状态,修改状态【修改与双击】
				let checkData = this.multipleSelection; //获取到选中行的值
				let pkBasicUserThis = ''; //以‘，’分开的主键
				if (this.checkIndex === 0) {
					this.$message({
						message: '请选择数据进行此操作！',
						type: 'warning'
					});
					return false;
				}
				//判断是否为【修改】
				if (ButtonState === 100) {
					//修改状态
					if (this.checkIndex != 1) {
						this.$message.warning('仅允许对一条数据进行查看！');
						return false;
					}
				}
				for (var i = 0; i < checkData.length; i++) {
					if (ButtonState !== 3 && ButtonState !== 2 && editStates != 'DbClick' && editStates != 'enableEdit') {}
					pkBasicUserThis += checkData[i].pkequ + ',';
				}
				pkBasicUserThis = pkBasicUserThis.substring(0, pkBasicUserThis.lastIndexOf(','));
				this.pkStr = pkBasicUserThis;
				return true;
			},
			//表单提交方法（保存方法）
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						const tempData = Object.assign({}, this.form);
						$axiosPost(this.saveUrl, qs.stringify(this.form)).then(res => {
							if (res.data.code === 'success') {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.dialogFormVisible = false;
								this.$refs[formName].resetFields();
								this.getTableData();
							} else {
								this.$notify.error({
									title: '错误',
									message: res.data.msg
								});
							}
							this.editSta = ''; //将【修改】【启用修改状态清空】
							this.form.states = ''; //将状态清空
						})
					}
				});
			},
			//关闭弹框方法
			callOf(formName) {
				this.$nextTick(() => {
					this.$refs[formName].resetFields();
					this.dialogFormVisible = false;
				});
			},
			//清空form表单方法
			clearForm(formName) {
				this.$nextTick(() => {
					this.form.pkequ = '';
					this.form.equcode = '';
					this.form.equname = '';
					this.form.xinghao = '';
					this.form.hmaxdiameter = '';
					this.form.hmaxlong = '';
					this.form.hmaxspace = '';
					this.form.hmaxtou = '';
					this.form.hmaxwei = '';
					this.form.hmintou = '';
					this.form.hminwei = '';
					this.form.hmixdiameter = '';
					this.form.hmixlong = '';
					this.form.hmixspace = '';
					this.form.ipaddress = '';
					this.form.isinteractive = '';
					this.form.way = '';
					this.form.zmaxdiameter = '';
					this.form.zmaxlong = '';
					this.form.zmaxspace = '';
					this.form.zmaxtou = '';
					this.form.zmaxwei = '';
					this.form.zmindiameter = '';
					this.form.zminlong = '';
					this.form.zminspace = '';
					this.form.zmintou = '';
					this.form.zminwei = '';
				});
			},
			//获取当前选中行数据方法
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.checkIndex = val.length;
			},
			//分页相关【下一页】操作
			handleSizeChangeFun(v) {
				this.pageSize = v;
				this.getTableData(); //更新列表
			},
			//分页相关【一页几条】操作
			handleCurrentChangeFun(v) {
				this.currentPage = v; //当前页
				this.getTableData(); //更新列表
			},
		}
	};
</script>
<style>
	.toCon {
		padding-right: 5px;
		/* border: 1px solid red; */
	}
</style>